@import "filters";

%defaultGradient {
  background: linear-gradient(135deg, #30CFD0 0%, rgba(180, 0, 241, 1) 100%);
}

%defaultGradient2 {
  background: linear-gradient(135deg, rgba(48, 207, 208, 0.75) 0%, rgba(180, 0, 241, .5) 100%);
}

%darkGradient {
  background: linear-gradient(125deg, rgba(0, 39, 103, 1) 0%, rgba(94, 0, 129, 1) 100%);
}

%darkGradient2 {
  background: linear-gradient(135deg, #30CFD0 0%, rgba(94, 0, 129, 1) 100%);
}


%lightGradient {
  background: linear-gradient(135deg, #30CFD0 0%, rgba(0, 255, 207, 1) 100%);
}

.bg-translucent {
  @extend %blurBackground;
}

.bg-darkGradient {
  @extend %darkGradient;
}

.text-defaultGradient {
  @extend %defaultGradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-lightGradient {
  @extend %lightGradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-darkGradient {
  @extend %darkGradient2;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
